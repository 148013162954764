import { Options, Vue } from "vue-class-component";
import { Option } from "@/components/select/select";
import { NAVBAR_DARK_VARIANTS, NAVBAR_LIGHT_VARIANTS, THEME_DARK_SKINS, THEME_LIGHT_SKINS } from "@/utils/themes";

@Options({
    name: "app-control-sidebar",
})
export default class ControlSidebar extends Vue {
    private navbarLightVariants: Array<Option> = NAVBAR_LIGHT_VARIANTS;
    private navbarDarkVariants: Array<Option> = NAVBAR_DARK_VARIANTS;
    private darkThemeSkins: Array<Option> = THEME_DARK_SKINS;
    private lightThemeSkins: Array<Option> = THEME_LIGHT_SKINS;
    private controlSidebarElement: HTMLElement | null = null;
    private controlSidebarButtonElement: HTMLElement | null = null;

    public async mounted(): Promise<void> {
        this.controlSidebarElement = this.$refs.controlSidebar as HTMLElement;
        this.controlSidebarButtonElement = document.getElementById("controlSidebarButton") as HTMLElement;
        document.addEventListener("click", this.documentClick);
    }

    public unmounted(): void {
        document.removeEventListener("click", this.documentClick);
    }

    private documentClick(event: Event) {
        const target: HTMLElement = event.target as HTMLElement;
        if (
            !this.$store.getters["ui/controlSidebarCollapsed"] &&
            this.controlSidebarElement !== target &&
            !this.controlSidebarElement.contains(target) &&
            this.controlSidebarButtonElement !== target &&
            !this.controlSidebarButtonElement.contains(target)
        ) {
            this.$store.dispatch("ui/toggleControlSidebar");
        }
    }

    public handleReset() {
        this.$store.dispatch("ui/setSkinReset");
    }

    get darkModeSelected() {
        return this.$store.getters["ui/darkModeSelected"];
    }

    set darkModeSelected(val: any) {
        this.$store.dispatch("ui/toggleDarkMode");
    }

    get darkMenuSelected() {
        return this.$store.getters["ui/darkMenuSelected"];
    }

    set darkMenuSelected(val: any) {
        this.$store.dispatch("ui/toggleDarkMenu");
    }

    get sidebarSkin() {
        return this.$store.getters["ui/darkMenuSelected"] ? "sidebar-dark-default" : "sidebar-light-default";
    }

    get themeSkin() {
        return this.$store.getters["ui/themeSkin"];
    }

    set themeSkin(value: any) {
        this.$store.dispatch("ui/setThemeSkin", value);
    }

    get navbarVariant() {
        return this.$store.getters["ui/navbarVariant"];
    }

    set navbarVariant(value: any) {
        this.$store.dispatch("ui/setNavbarVariant", value);
    }
}
