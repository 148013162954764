/* eslint-disable @typescript-eslint/no-unused-vars */
import { Options, Vue } from "vue-class-component";
import TabsViews from "./tabs-views/tabs-views.vue";
import Messages from "./messages/messages.vue";
import Notifications from "./notifications/notifications.vue";
import User from "./user/user.vue";

@Options({
    components: {
        "tabs-views": TabsViews,
        "messages-dropdown": Messages,
        "notifications-dropdown": Notifications,
        "user-dropdown": User,
    },
})
export default class Header extends Vue {
    private headerElement: HTMLElement | null = null;

    public async mounted(): Promise<void> {
        this.headerElement = document.getElementById("layout-header") as HTMLElement;
    }

    public onToggleMenuSidebar(): void {
        this.$store.dispatch("ui/toggleMenuSidebar");
    }

    public onToggleControlSidebar(): void {
        this.$store.dispatch("ui/toggleControlSidebar");
    }

    get isMenuSidebarCollapsed() {
        return this.$store.getters["ui/menuSidebarCollapsed"];
    }

    get darkModeSelected() {
        return this.$store.getters["ui/darkModeSelected"];
    }

    get navbarVariant() {
        return this.$store.getters["ui/navbarVariant"];
    }
}
