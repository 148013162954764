import { Options, Vue } from "vue-class-component";
import DropdownNav from "@/components/dropdown-nav/dropdown-nav.vue";
// import {DateTime} from 'luxon';

@Options({
    name: "user-dropdown",
    components: {
        "app-dropdown-nav": DropdownNav,
    },
})
export default class User extends Vue {
    private logout() {
        this.$store.dispatch("auth/logout");
    }
    get userInfo() {
        return this.$store.getters["auth/userInfo"];
    }
}
