import { Options, Vue } from "vue-class-component";
import DropdownNav from "@/components/dropdown-nav/dropdown-nav.vue";

@Options({
    name: "messages-dropdown",
    components: {
        "app-dropdown-nav": DropdownNav,
    },
})
export default class Messages extends Vue {}
