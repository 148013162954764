import { Options, Vue } from "vue-class-component";
import DropdownNav from "@/components/dropdown-nav/dropdown-nav.vue";
import ElementResizeDetectorMaker from "element-resize-detector";

@Options<TabsViews>({
    name: "tags-views",
    components: {
        "app-dropdown-nav": DropdownNav,
    },
    watch: {
        $route: function (to: any) {
            this.moveCurTabsItem(to);
        },
    },
})
export default class TabsViews extends Vue {
    private itemElement: HTMLElement | null = null;
    private erd: any = null;
    public listWidth: number = null;
    public itemWidth: number = null;
    public itemLeft: number = null;
    public selectedClose: string = null;
    public closes: any = [
        {
            key: "all",
            icon: "trash-bin-sharp",
            label: "全部删除",
        },
        {
            key: "other",
            icon: "trash-bin-outline",
            label: "删除其它",
        },
    ];

    public mounted(): void {
        this.itemElement = document.getElementById("navbar-tabs-list-item") as HTMLElement;

        this.erd = this.erd ? this.erd : ElementResizeDetectorMaker();
        // const elementResizeDetectorMaker = require("element-resize-detector");
        // this.erd = elementResizeDetectorMaker();
        this.erd.listenTo(document.getElementById("navbar-tabs-list"), (el: HTMLElement) => (this.listWidth = el.offsetWidth));
        this.erd.listenTo(document.getElementById("navbar-tabs-list-item"), (el: HTMLElement) => (this.itemWidth = el.offsetWidth));
    }

    public beforeDestroy(): void {
        if (this.erd) {
            this.erd.uninstall(document.getElementById("navbar-tabs-list"));
            this.erd.uninstall(document.getElementById("navbar-tabs-list-item"));
        }
    }

    //关闭页面
    public async changeClose(key: string) {
        this.selectedClose = key;
        if ("all" === key) {
            await this.$store.dispatch("router/closeAll");
        } else if ("other" === key) {
            await this.$store.dispatch("router/closeAll");
            await this.$store.dispatch("router/putTabsList", this.$router.currentRoute.value);
        }
    }

    //移动到当前Tab
    public moveCurTabsItem(to: any) {
        const itemElements = this.itemElement.getElementsByTagName("li");
        if (itemElements.length < 1) return;
        const curItemIndex = Object.keys(this.getTabsList).findIndex((value) => value === to.name);
        if (curItemIndex < 0) return;
        const curItemWidth = itemElements.item(curItemIndex).offsetWidth;
        const curItemOffset = itemElements.item(curItemIndex).offsetLeft;
        if (this.itemWidth < this.listWidth) {
            this.itemElement.style.left = "0px";
            return;
        }
        if (curItemOffset < this.listWidth / 2 - curItemWidth / 2) {
            this.itemElement.style.left = "0px";
        } else if (this.itemWidth - curItemOffset < this.listWidth / 2 + curItemWidth / 2) {
            this.itemElement.style.left = 0 - (this.itemWidth - this.listWidth) + "px";
        } else {
            this.itemElement.style.left = 0 - (curItemOffset - this.listWidth / 2 + curItemWidth / 2) + "px";
        }
    }

    //向前移动
    public onTabsMoveBeforeStart(): void {
        this.onTabsMoveStart("before");
    }

    public onTabsMoveBeforeClick(): void {
        const left: number = parseInt(this.itemElement.style.left) || 0;
        if (this.itemWidth > this.listWidth) {
            if (left < 0) {
                const offset = left + 30 > 0 ? 0 : left + 30;
                this.itemElement.style.left = offset + "px";
            }
        } else {
            this.itemElement.style.left = "0px";
        }
    }

    //向后移动
    public onTabsMoveBackStart(): void {
        this.onTabsMoveStart("back");
    }

    public onTabsMoveBackClick(): void {
        const left: number = parseInt(this.itemElement.style.left) || 0;
        const offsetLeft = this.itemWidth - this.listWidth;
        if (this.itemWidth > this.listWidth) {
            if (left + this.itemWidth >= this.listWidth) {
                const offset = left - 30 < 0 - offsetLeft ? 0 - offsetLeft : left - 30;
                this.itemElement.style.left = offset + "px";
            }
        } else {
            this.itemElement.style.left = "0px";
        }
    }

    private tabsMoveTimeout = 0;
    private tabsMoveInterval = 0;

    public onTabsMoveStart(way: string): void {
        clearTimeout(this.tabsMoveTimeout);
        this.tabsMoveTimeout = 0;
        this.tabsMoveTimeout = setTimeout(() => {
            this.tabsMoveInterval = setInterval(() => {
                if (way === "before") {
                    this.onTabsMoveBeforeClick();
                } else if (way === "back") {
                    this.onTabsMoveBackClick();
                } else {
                    this.onTabsMoveEnd();
                }
                if (this.tabsMoveTimeout === 0 || this.tabsMoveInterval === 0) this.onTabsMoveEnd();
            }, 100);
        }, 600);
    }

    public onTabsMoveEnd(): void {
        clearTimeout(this.tabsMoveTimeout);
        this.tabsMoveTimeout = 0;
        clearInterval(this.tabsMoveInterval);
        this.tabsMoveInterval = 0;
    }

    //tabs控件是否超长
    get isTabsSuperLong() {
        return this.listWidth < this.itemWidth;
    }

    //获取tabs_list
    get getTabsList() {
        return this.$store.getters["router/tabsList"];
    }

    get themeSkin() {
        return this.$store.getters["ui/themeSkin"];
    }
}
