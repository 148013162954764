import { Options, Vue } from "vue-class-component";
import DropdownNavMenu from "./dropdown-nav-menu/dropdown-nav-menu.vue";

@Options({
    name: "app-dropdown-nav",
    components: {
        "app-dropdown-nav-menu": DropdownNavMenu,
    },
    props: {
        size: {
            type: String,
            default: () => "md",
        },
        mode: {
            type: String,
            default: () => "button",
        },
        side: {
            type: String,
            default: () => "right",
        },
    },
})
export default class DropdownNav extends Vue {
    private dropdownElement: HTMLElement;
    public isOpen = false;

    public mounted(): void {
        this.dropdownElement = this.$refs.dropdownNav as HTMLElement;
        document.addEventListener("click", this.documentClick);
    }

    public unmounted(): void {
        document.removeEventListener("click", this.documentClick);
    }

    private documentClick(event: Event) {
        const target: HTMLElement = event.target as HTMLElement;
        if (this.dropdownElement !== target && !this.dropdownElement.contains(target)) {
            this.isOpen = false;
        }
    }

    private toggleDropdown() {
        this.isOpen = !this.isOpen;
    }
}
